<template>
  <div id="rightBottom">
    <div class="lb-title">各乡镇候选人情况</div>
    <dv-scroll-board class="lb-content" :config="config" />
  </div>
</template>

<script>
export default {
  data() {
    return {
      config: {
        header: ["乡 / 镇", "重残三类(人)", "轻残三类(人)"],
        data: [
          ["琉璃庙镇", 18, 3],
          ["渤海镇", 6, 6],
          ["九渡河镇", 6, 5],
          ["怀柔镇", 3, 3],
          ["庙城镇", 4, 0],
          ["桥梓镇", 3, 1],
          ["宝山镇", 4, 0],
          ["北房镇", 3, 0],
          ["汤河口镇", 3, 0],
          ["龙山街道", 2, 1],
          ["杨宋镇", 1, 1],
          ["怀北镇", 2, 0],
          ["喇叭沟门满族乡", 0, 2],
          ["雁栖镇", 2, 0],
          ["长哨营乡", 0, 1],
          ["泉河街道", 1, 0],
        ],
        rowNum: 8, //表格行数
        headerHeight: 40,
        headerBGC: "#5689EA", //表头
        oddRowBGC: "", //奇数行
        evenRowBGC: "", //偶数行
        index: true,
        columnWidth: [50, 130, 110, 110],
        align: ["center"],
      },
    }
  },
  components: {},
  mounted() {},
  methods: {},
}
</script>

<style lang="scss" scoped>
$box-width: 100%;
$box-height: 480px;

#rightBottom {
  padding: 20px;
  height: $box-height;
  width: $box-width;
  border-radius: 10px;
  .lb-title {
    width: 100%;
    height: 8%;
    // display: flex;
    // align-items: center;
    font-weight: 500;
    color: #fff;
    font-size: 14px;
    text-shadow: 0 0 10px #38cefe, 0 0 20px #38cefe, 0 0 30px #38cefe,
      0 0 40px #38cefe;
  }
  .lb-content {
    width: 100%;
    height: 92%;
  }
}
</style>
