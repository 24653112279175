<template>
  <div id="leftBottom">
    <div class="lb-title">各乡镇培训计划</div>
    <dv-scroll-board class="lb-content" :config="config" @click="skipTo" />
  </div>
</template>

<script>
export default {
  data() {
    return {
      config: {
        header: ["乡 / 镇", "培训场次", "人次", "培训内容", "就业人数"],
        data: [
          ["琉璃庙镇", 11, 209, "心理健康讲座，面点制作，信息技术", 164],
          ["九渡河镇", 10, 170, "面点制作，茶艺，信息技术", 159],
          ["渤海镇", 9, 180, "茶艺，健康管理，收纳整理", 142],
          ["怀柔镇", 8, 168, "心理健康讲座，茶艺", 109],
          ["宝山镇", 8, 160, "信息技术，残疾相关", 105],
          ["庙城镇", 7, 175, "面点制作，美甲", 97],
          ["北房镇", 7, 133, "健康管理，收纳整理", 82],
          ["桥梓镇", 7, 175, "残疾相关，办公技能", 82],
          ["汤河口镇", 8, 184, "心理健康讲座，美甲", 72],
          ["怀北镇", 9, 189, "面点制作，办公技能", 60],
          ["喇叭沟门满族乡", 7, 133, "美甲，收纳整理", 51],
          ["雁栖镇", 7, 140, "茶艺，残疾相关", 50],
          ["长哨营满族乡", 8, 160, "心理健康讲座，健康管理", 43],
          ["杨宋镇", 6, 99, "收纳整理，办公技能", 39],
          ["龙山街道", 8, 120, "面点制作，茶艺，收纳整理", 23],
          ["泉河街道", 7, 105, "心理健康讲座，信息技术，美甲", 23],
          ["北房镇", 32, 79, "社区公益岗位", 82],
          ["宝山镇", 32, 130, "社区公益岗位", 105],
          ["怀柔镇", 32, 72, "社区公益岗位", 109],
          ["渤海镇", 32, 203, "社区公益岗位", 142],
          ["九渡河镇", 32, 167, "社区公益岗位", 159],
          ["汤河口镇", 32, 81, "社区公益岗位", 72],
          ["琉璃庙镇", 32, 107, "社区公益岗位", 164],
          ["庙城镇", 32, 71, "社区公益岗位", 97],
          ["桥梓镇", 32, 85, "社区公益岗位", 82],
          ["怀北镇", 32, 35, "社区公益岗位", 50],
          ["雁西镇", 32, 40, "社区公益岗位", 43],
          ["喇叭沟门满族乡", 32, 43, "社区公益岗位", 51],
          ["长哨营满族乡", 32, 45, "社区公益岗位", 43],
          ["杨宋镇", 32, 31, "社区公益岗位", 39],
          ["桥梓镇", 2, 69, "垃圾分类指导", 82],
          ["渤海镇", 3, 188, "垃圾分类指导", 142],
          ["九渡河镇", 2, 134, "垃圾分类指导", 159],
          ["北房镇", 2, 64, "垃圾分类指导", 82],
          ["庙城镇", 2, 66, "垃圾分类指导", 97],
          ["怀柔镇", 2, 69, "垃圾分类指导", 109],
          ["杨宋镇", 1, 32, "数据速录", 39],
          ["琉璃庙镇", 2, 119, "数据速录", 164],
          ["渤海镇", 2, 50, "数据速录", 142],
          ["怀柔镇", 2, 50, "茶艺", 109],
          ["杨宋镇", 2, 37, "茶艺", 39],
          ["琉璃庙镇", 2, 37, "茶艺", 164],
          ["汤河口镇", 2, 30, "茶艺", 72],
          ["长哨营满族乡", 1, 31, "茶艺", 43],
          ["宝山镇", 1, 30, "茶艺", 105],
          ["喇叭沟门满族乡", 1, 35, "茶艺", 51],
          ["雁栖镇", 1, 28, "茶艺", 50],
        ],
        rowNum: 8, //表格行数
        headerHeight: 40,
        headerBGC: "#5689EA", //表头
        oddRowBGC: "", //奇数行
        evenRowBGC: "", //偶数行
        index: true,
        columnWidth: [60, 150, 100, 100, 300, 150],
        align: ["center"],
      },
    }
  },
  components: {},
  mounted() {},
  methods: {
    skipTo(config) {
      console.log(config)
      this.$router.push({
        path: "/town",
        query: { address: "怀柔区九渡河镇" },
      })
    },
  },
}
</script>

<style lang="scss" scoped>
$box-width: 100%;
$box-height: 480px;

#leftBottom {
  padding: 20px;
  height: $box-height;
  width: $box-width;
  border-radius: 10px;
  .lb-title {
    width: 100%;
    height: 8%;
    // display: flex;
    // align-items: center;
    font-weight: 500;
    color: #fff;
    font-size: 14px;
    text-shadow: 0 0 10px #38cefe, 0 0 20px #38cefe, 0 0 30px #38cefe,
      0 0 40px #38cefe;
  }
  .lb-content {
    width: 100%;
    height: 92%;
    cursor: pointer;
  }
}
</style>
