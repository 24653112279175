<template>
  <div id="index" ref="appRef">
    <div class="bg">
      <dv-loading v-if="loading">Loading...</dv-loading>
      <div v-else class="host-body">
        <div class="d-flex jc-center">
          <dv-decoration-10 class="dv-dec-10" />
          <div class="d-flex jc-center">
            <dv-decoration-8 class="dv-dec-8" :color="decorationColor" />
            <div class="title" @click="titleClick()">
              <!-- <span class="title-text">淳安县残疾人帮扶性就业试点看板</span> -->
              <span class="title-text">怀柔区残疾人帮扶性就业看板</span>
              <dv-decoration-6
                class="dv-dec-6"
                :reverse="true"
                :color="['#50e3c2', '#67a1e5']"
              />
            </div>
            <dv-decoration-8
              class="dv-dec-8"
              :reverse="true"
              :color="decorationColor"
            />
          </div>
          <dv-decoration-10 class="dv-dec-10-s" />
        </div>

        <div
          class="body-box"
          style="flex-direction: row;
    justify-content: space-between;"
        >
          <div class="content-left">
            <dv-border-box-12>
              <left-top />
            </dv-border-box-12>
            <dv-border-box-13 style=" margin-top: 10px;">
              <center-top />
            </dv-border-box-13>
          </div>
          <div class="content-center">
            <!-- style="background-color: rgba(19,25,47,.6);" -->
            <dv-border-box-12>
              <left-bottom />
            </dv-border-box-12>
            <dv-border-box-12>
              <center-bottom />
            </dv-border-box-12>
          </div>
          <div class="content-right">
            <dv-border-box-12>
              <!---->
              <rigth-top />
            </dv-border-box-12>
            <dv-border-box-13 style=" margin-top: 10px;">
              <right-bottom />
            </dv-border-box-13>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import drawMixin from "../../utils/drawMixin"
import { formatTime } from "../../utils/index.js"

import leftTop from "./components/leftTop.vue"
import leftBottom from "./components/leftBottom.vue"
import centerTop from "./components/centerTop.vue"
import centerBottom from "./components/centerBottom.vue"
import rigthTop from "./components/rightTop.vue"
import rightBottom from "./components/rightBottom.vue"

export default {
  components: {
    leftTop,
    leftBottom,
    centerTop,
    centerBottom,
    rigthTop,
    rightBottom,
  },
  mixins: [drawMixin],
  data() {
    return {
      timing: null,
      loading: true,
      dateDay: null,
      dateYear: null,
      dateWeek: null,
      weekday: ["周日", "周一", "周二", "周三", "周四", "周五", "周六"],
      decorationColor: ["#568aea", "#568aea"],
    }
  },

  mounted() {
    this.timeFn()
    this.cancelLoading()
    this.deleteUser()
  },

  beforeDestroy() {
    clearInterval(this.timing)
  },
  methods: {
    titleClick() {
      this.$router.go(-1)
    },
    async deleteUser() {
      // const { data } = await this.$http({
      //   method: "get",
      //   url: "usersEdit",
      //   data: {
      //     id: "11",
      //   },
      // })
      // console.log(data)
    },
    timeFn() {
      this.timing = setInterval(() => {
        this.dateDay = formatTime(new Date(), "HH: mm: ss")
        this.dateYear = formatTime(new Date(), "yyyy-MM-dd")
        this.dateWeek = this.weekday[new Date().getDay()]
      }, 1000)
    },
    cancelLoading() {
      setTimeout(() => {
        this.loading = false
      }, 500)
    },
  },
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/chunan.scss";

.bg {
    background-image: linear-gradient(#022165, #0252c3) !important;
  }
  .bg-color-black {
    background-color: rgba(13, 28, 83, 0.3) !important;
  }
</style>
