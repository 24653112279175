<template>
  <div id="leftTop">
    <div v-for="(item, index) in tongjishuju" :key="index" class="people">
      <div class="name">{{ item.name }}</div>
      <div class="value">
        {{ item.value }}<span class="unit">{{ item.unit }}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      tongjishuju: [
        {
          name: "残疾人总数",
          value: "28,853",
          unit: "人",
        },
        {
          name: "就业年龄段",
          value: "9,753",
          unit: "人",
        },
        {
          name: "培训总数",
          value: "2,500",
          unit: "人次",
        },
        {
          name: "就业/累计人数",
          value: "1,301/7,587",
          unit: "人",
        },
        {
          name: "近三个月到期提醒",
          value: "44",
          unit: "人",
        },
        {
          name: "不足一年退休提醒",
          value: "15",
          unit: "人",
        },
      ],
    }
  },
  components: {},
  mounted() {},
  methods: {},
}
</script>

<style lang="scss" scoped>
$box-width: 100%;
$box-height: 480px;

#leftTop {
  padding: 20px;
  height: $box-height;
  width: $box-width;
  border-radius: 10px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  .people {
    width: 48%;
    margin-bottom: 10px;
    background-color: rgba(86, 137, 234, 0.3);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .name {
      color: #fff;
    }
    .value {
      margin-top: 20px;
      font-size: 28px;
      color: #3de4c8;
    }
    .unit {
      color: #fff;
      font-size: 16px;
    }
  }
}
</style>
