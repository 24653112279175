<template>
  <div id="rigthTop">
    <div class="lb-title">运营事件</div>
    <div id="offline" style="width:100%;height:50%"></div>
    <div id="typeline" style="width:100%;height:50%"></div>
    <!-- <dv-scroll-board class="lb-content" :config="config" /> -->
  </div>
</template>

<script>
import * as echarts from "echarts"

export default {
  data() {
    return {
      config: {
        header: ["乡 / 镇", "姓名", "企业", "办事"],
        data: [
          ["渤海镇", "曹玉龙", "北京城建", "重签合同"],
          ["琉璃庙镇", "于海生", "幻想纵横", "银行流水"],
          ["渤海镇", "程仕有", "北京城建", "重签合同"],
          ["九渡河镇", "白玉柱", "北京城建", "重签合同"],
          ["九渡河镇", "刘满田", "北京城建", "重签合同"],
          ["琉璃庙镇", "马志强", "幻想纵横", "银行流水"],
          ["怀柔镇", "赵海波", "北京城建", "残疾证扫描件"],
          ["怀柔镇", "段福生", "北京城建", "残疾证扫描件"],
          ["龙山街道", "程钢", "领语堂瑞思", "重签合同"],
          ["怀柔镇", "杜红义", "北京城建", "残疾证扫描件"],
          ["九渡河镇", "李雪娟", "北京城建", "残疾证扫描件"],
          ["渤海镇", "王建军", "北京城建", "残疾证扫描件"],
          ["琉璃庙镇", "席金平", "爱迪德技术", "社保一次性清算"],
          ["怀柔镇", "陈海良", "利德华福电气", "工资未发"],
          ["琉璃庙镇", "刘进明", "幻想纵横", "银行流水"],
          ["杨宋镇", "王忠", "国盛兴业", "申报材料"],
          ["长哨营乡", "张国山", "龙湖物业", "银行流水"],
          ["渤海镇", "曹玉龙", "北京城建", "重签合同"],
          ["怀柔镇", "牛婧宇", "猫眼文化", "生育津贴"],
          ["渤海镇", "程仕有", "北京城建", "重签合同"],
          ["九渡河镇", "白玉柱", "北京城建", "重签合同"],
          ["九渡河镇", "刘满田", "北京城建", "重签合同"],
          ["北房镇", "王官", "慧与", "补充材料"],
          ["九渡河镇", "李雪娟", "北京城建", "残疾证扫描件"],
          ["怀柔镇", "杜红义", "北京城建", "残疾证扫描件"],
          ["怀柔镇", "段福生", "北京城建", "残疾证扫描件"],
          ["怀柔镇", "杜顺旺", "和讯在线", "银行流水"],
          ["怀柔镇", "赵海波", "北京城建", "残疾证扫描件"],
          ["渤海镇", "王建军", "北京城建", "残疾证扫描件"],
          ["北房镇", "孟宪强", "慧与", "补充材料"],
          ["渤海镇", "杨书泉", "米多其", "离职证明"],
          ["怀柔镇", "胡俊玲", "和讯在线", "银行流水"],
          ["北房镇", "于娟", "梅赛德斯-奔驰", "银行卡变更"],
          ["琉璃庙镇", "杨俊霞", "点点未来", "银行流水"],
        ],

        rowNum: 8, //表格行数
        headerHeight: 40,
        headerBGC: "#5689EA", //表头
        oddRowBGC: "", //奇数行
        evenRowBGC: "", //偶数行
        index: true,
        columnWidth: [50, 80, 70, 110],
        align: ["center"],
      },
    }
  },
  components: {},
  mounted() {
    this.OfflineOptions()
    this.typelineOptions()
  },
  methods: {
    OfflineOptions() {
      var offlinechartDom = document.getElementById("offline")
      var offlineChart = echarts.init(offlinechartDom)

      var scaleData = [
        {
          name: "九渡河镇",
          value: 6,
        },
        {
          name: "北房镇",
          value: 3,
        },
        {
          name: "怀柔镇",
          value: 10,
        },
        {
          name: "杨宋镇",
          value: 1,
        },
        {
          name: "渤海镇",
          value: 7,
        },
        {
          name: "琉璃庙镇",
          value: 5,
        },
        {
          name: "长哨营乡",
          value: 1,
        },
        {
          name: "龙山街道",
          value: 1,
        },
      ]

      var rich = {
        white: {
          color: "#ddd",
          align: "center",
          padding: [5, 0],
        },
      }
      // var placeHolderStyle = {
      //   normal: {
      //     label: {
      //       show: false,
      //     },
      //     labelLine: {
      //       show: false,
      //     },
      //     color: "rgba(0, 0, 0, 0)",
      //     borderColor: "rgba(0, 0, 0, 0)",
      //     borderWidth: 0,
      //     shadowBlur: 10,
      //   },
      // }
      var data = []
      for (var i = 0; i < scaleData.length; i++) {
        data.push(
          {
            value: scaleData[i].value,
            name: scaleData[i].name,
            itemStyle: {
              normal: {
                borderWidth: 2,
                shadowBlur: 10,
                borderColor: new echarts.graphic.LinearGradient(0, 0, 1, 1, [
                  {
                    offset: 0,
                    color: "#7777eb",
                  },
                  {
                    offset: 1,
                    color: "#70ffac",
                  },
                ]),
                shadowColor: "rgba(142, 152, 241, 0.6)",
              },
            },
          },
          // {
          //   value: 4,
          //   name: "",
          //   itemStyle: placeHolderStyle,
          // }
        )
      }
      var seriesObj = [
        {
          name: "",
          type: "pie",
          clockWise: false,
          radius: [40, 55],
          hoverAnimation: false,
          itemStyle: {
            normal: {
              label: {
                show: true,
                position: "outside",
                color: "#ddd",
                formatter: function(params) {
                  var percent = 0
                  var total = 0
                  for (var i = 0; i < scaleData.length; i++) {
                    total += scaleData[i].value
                  }
                  percent = ((params.value / total) * 100).toFixed(0)
                  if (params.name !== "") {
                    return params.name + "{white|" + "" + percent + "%}"
                  } else {
                    return ""
                  }
                },
                rich: rich,
              },

              labelLine: {
                length: 10,
                length2: 5,
                show: true,
                color: "#00ffff",
              },
            },
          },
          data: data,
        },
      ]
      var option = {
        tooltip: {
          show: false,
        },
        legend: {
          show: false,
        },
        toolbox: {
          show: false,
        },
        series: seriesObj,
      }
      offlineChart.setOption(option)
      window.addEventListener("resize", function() {
        offlineChart.resize() //下面可以接多个图
      })
    },
    typelineOptions() {
      var offlinechartDom = document.getElementById("typeline")
      var offlineChart = echarts.init(offlinechartDom)

      var scaleData = [
        {
          name: "工资未发",
          value: 1,
        },
        {
          name: "残疾证扫描件",
          value: 10,
        },
        {
          name: "生育津贴",
          value: 1,
        },
        {
          name: "申报材料",
          value: 1,
        },
        {
          name: "社保一次性清算",
          value: 1,
        },
        {
          name: "离职证明",
          value: 1,
        },
        {
          name: "补充材料",
          value: 2,
        },
        {
          name: "重签合同",
          value: 9,
        },
        {
          name: "银行卡变更",
          value: 1,
        },
        {
          name: "银行流水",
          value: 7,
        },
      ]

      var rich = {
        white: {
          color: "#ddd",
          align: "center",
          padding: [5, 0],
        },
      }
      // var placeHolderStyle = {
      //   normal: {
      //     label: {
      //       show: false,
      //     },
      //     labelLine: {
      //       show: false,
      //     },
      //     color: "rgba(0, 0, 0, 0)",
      //     borderColor: "rgba(0, 0, 0, 0)",
      //     borderWidth: 0,
      //     shadowBlur: 10,
      //   },
      // }
      var data = []
      for (var i = 0; i < scaleData.length; i++) {
        data.push(
          {
            value: scaleData[i].value,
            name: scaleData[i].name,
            itemStyle: {
              normal: {
                borderWidth: 2,
                shadowBlur: 10,
                borderColor: new echarts.graphic.LinearGradient(0, 0, 1, 1, [
                  {
                    offset: 0,
                    color: "#7777eb",
                  },
                  {
                    offset: 1,
                    color: "#70ffac",
                  },
                ]),
                shadowColor: "rgba(142, 152, 241, 0.6)",
              },
            },
          },
          // {
          //   value: 4,
          //   name: "",
          //   itemStyle: placeHolderStyle,
          // }
        )
      }
      var seriesObj = [
        {
          name: "",
          type: "pie",
          clockWise: false,
          radius: [40, 55],
          hoverAnimation: false,
          itemStyle: {
            normal: {
              label: {
                show: true,
                position: "outside",
                color: "#ddd",
                formatter: function(params) {
                  var percent = 0
                  var total = 0
                  for (var i = 0; i < scaleData.length; i++) {
                    total += scaleData[i].value
                  }
                  percent = ((params.value / total) * 100).toFixed(0)
                  if (params.name !== "") {
                    return params.name + "{white|" + "" + percent + "%}"
                  } else {
                    return ""
                  }
                },
                rich: rich,
              },

              labelLine: {
                length: 10,
                length2: 5,
                show: true,
                color: "#00ffff",
              },
            },
          },
          data: data,
        },
      ]
      var option = {
        tooltip: {
          show: false,
        },
        legend: {
          show: false,
        },
        toolbox: {
          show: false,
        },
        series: seriesObj,
      }
      offlineChart.setOption(option)
      window.addEventListener("resize", function() {
        offlineChart.resize() //下面可以接多个图
      })
    },
  },
}
</script>

<style lang="scss" scoped>
$box-width: 100%;
$box-height: 480px;

#rigthTop {
  padding: 20px;
  height: $box-height;
  width: $box-width;
  border-radius: 10px;
  .lb-title {
    width: 100%;
    height: 8%;
    // display: flex;
    // align-items: center;
    font-weight: 500;
    color: #fff;
    font-size: 14px;
    text-shadow: 0 0 10px #38cefe, 0 0 20px #38cefe, 0 0 30px #38cefe,
      0 0 40px #38cefe;
  }
  .lb-content {
    width: 100%;
    height: 92%;
  }
}

::v-deep .dv-capsule-chart .capsule-item div {
  height: 18px;
}
::v-deep .dv-capsule-chart .capsule-item {
  height: 20px;
}
::v-deep .dv-capsule-chart .label-column {
  font-size: 16px;
  margin-top: 5px;
}
::v-deep .dv-capsule-chart .unit-label {
  font-size: 16px;
}
::v-deep
  .dv-capsule-chart
  .capsule-item
  .capsule-item-column
  .capsule-item-value {
  font-size: 16px;
}
</style>
